

.ham{
    background: none;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.ham.active .top{
    y: 45;
    transform: rotate(45deg);
    transform-origin: center;
}

.ham.active .bottom{
    y: 45;
    transform: rotate(-45deg);
}

.ham rect {
    transform-origin: center;
    transform-box: fill-box;
    transition:
        transform 100ms ease-in, 
        y 100ms ease-in 100ms;
}

.ham.active rect{
    transform-origin: center; 
    transform-box: fill-box;
    transition: 
        y 100ms ease-in, 
        transform 100ms ease-in 100ms;
}