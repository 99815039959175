@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap');


@font-face {
  font-family: Magdelin;
  src: url("assets/fonts/Magdelin-webfont/magdelin-bold-webfont.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: Magdelin;
  src: url("assets/fonts/Magdelin-webfont/magdelin-regular-webfont.woff2") format("woff2");
  font-weight: normal;
}



@font-face {
  font-family: Greycliff;
  src: url("assets/fonts/Greycliff/GreycliffCF-ExtraBold.woff2");
}





*{
  box-sizing: border-box;
}

:root{
  --blue: #C2D0EA;
  --orange: #D96A5B;
}

h2, h1{
  line-height: 1.3;
}

/* nav */
nav{
  background-color: var(--blue);
  padding: 1rem 1rem 1rem 1rem;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 6rem;
  z-index: 9999;
  border-bottom: solid 1px gray;
}

a{
  text-decoration: none;
  font-family: Magdelin;
  font-weight: bold;
  color: black;
}


.icon{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.ic{
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 0;
}

h4{
  font-family: Greycliff;
  font-size: 1rem;
}

.container{
  display:flex;
  justify-content: space-between;
  gap: .67rem;
  align-items: center;
}

.links{
  display: flex;
  gap: 2rem;
}

button:not(.ham) {
  background: var(--orange);
  border: var(--orange) solid 2px;
  border-radius: 10px;
  color: black;
  padding: 1.4em 2em 1.4em 2em;
  font-family: Magdelin;
  font-size: 0.6rem;
  font-weight: bold;
  cursor: pointer;
}

button:hover:not(.ham) {
  background: rgba(0,0,0,0);
  color: #100f3e;
}

nav a:hover{
  color: var(--orange);
}

nav a{
  display: inline-block;
  position: relative;
}

/* underline */
.links a:hover:after {
  content: "";
  height: 3px;
  width: 40%;
  background-color: var(--orange);
  border-radius: 2px;
  position: absolute;
  bottom: -.5em;
  left: 50%;
  transform: translate(-50%);
}

.hamburger{
  display:none;
}

.hamContainer{
  display: none;
}

.hamlinks{
  opacity: 0;
}


/* jumbotron */
.jumbotron{
  background-color: var(--blue);
  position: relative;
  padding-top: 4rem;
  display:flex;
  justify-content: space-between;
  padding-left: 15vw;
  padding-right: 15vw;

}

.stentContainer{
  flex-basis: 20%;
}

.stent{
  max-height: clamp(12rem, 7.7455rem + 18.1524vw, 19rem);
  position: absolute;
  bottom: 0%;
}

.title-text{
  flex-basis: 50%;
  padding-bottom: 2rem;

}

.jumbotron h1{
  font-family: 'Greycliff';
  font-style: normal;
  font-size: clamp(1rem, -2.5rem + 9vw, 2.0rem);
  margin-bottom: .7em;
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
  font-weight: bold;
}

.jumbotron h2{
  font-family: 'Magdelin';
  font-style: normal;
  font-weight: normal;
  margin-bottom: 1rem;
  font-size: clamp(1.1rem, -3.5rem + 8vw, 1.3rem);
}

.jumbotron button{
  font-size: clamp(.5rem, -1.5rem + 8vw, .8rem);
  border-radius: 10px;
  padding: .5rem 2rem .5rem 2rem;
}












.nums{
  display: flex; 
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--orange);
  padding: 1rem 1rem;
}

.pane{
  color: white;
  text-align: center;
}


h3.number{
  font-size: 2rem;
  margin-block: 0;
  font-family: Magdelin;
}

h4.description{
  margin: 0;
  font-size: .8rem;
  font-weight: normal;
} 


.who{
  padding: 4rem 1rem 2rem 1rem;
  display: flex; 
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap-reverse;
}

.who .txt{
  font-family: Magdelin;
  flex-basis: 30%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 25rem;
  min-width: 11rem;
}
.who p{
  font-family: Magdelin;
}

.who>img{
  height: auto;
  width: 100%;
  border-radius: 1.5rem;
  max-width: 25rem;
  flex-grow:0;
  flex-basis: 30%;
}

.who h2{
  margin-bottom: .8rem;
  font-family: Greycliff;

}


.inNews{
  height: 15rem;
}

.whoContainer{
  background-size: cover;
}

.who button{
  font-size: .9rem;
  padding: .6rem 1.4rem;
  margin: .8rem auto;
}

.quote{
  font-family: Mermaid;
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 20rem;
}

.who p{
  margin: .3rem .3rem;
}

.interested{
  background-color: var(--blue);
  padding: 2rem 2rem;
  text-align: center;
  font-family: Magdelin;
}

.interested h2{
  margin-bottom:.8rem;
}

.interested button{
  background-color: var(--orange);
  padding: .7rem 2rem;;
  font-size: .9rem;

}


/* footer */
footer{
  display: flex;
  background-color: #161314;
  padding: 1rem 0 1rem 2rem;
  margin-top: auto;
  
}

.about, .home {
  display: flex;
  min-height: calc(100vh - 6rem);
  flex-direction: column;
}

.body{
  flex-grow: 1;
}

h2{
  margin: 0;
}

body {
  padding-top: 6rem;
  height: 100%;
  background-color: var(--blue);
}


footer>ul{
  list-style: none;
   
}

footer li a{
  font-family: Magdelin;
  font-weight: bold;
  color: #edecc9;
}

footer li a:hover{
  color: var(--orange);
  cursor: pointer;
}

.imgContainer{
  margin-top: 1em;
}


/* about us page */
.about{
  background-color: var(--blue);
}

.abt-container-head, .abt-container-mission, .abt-container-problem{
  display: flex;
}
.abt-container-problem{
  align-items: center;
}

.abt-img{
  flex-basis: 50%;
  flex-grow: 1;
  max-height: fit-content;
}

.abt-txt{
  flex-basis: 50%;
  padding: 2rem;
  text-align: center;
  font-family: 'Magdelin';
  display: flex;
  justify-content: center;
  align-items: center;
}
.abt-txt h2{
  font-family: 'Greycliff';
}

.abt-img img{
  width: 100%;
  height: auto;
  display: block;
}


.txt-wrapper{
  max-width: 20rem;
  align-self: stretch;
}

.abt-txt .txt-wrapper{
  align-self: center;
}

.abt-container-mission .abt-txt, .abt-container-problem .abt-txt{
  text-align: left;
}


.abt-container-mission{
  flex-direction: row-reverse;
}

.abt-container-mission h3, .abt-container-problem h3{
  color: var(--orange);
  margin: 0;
}



.abt-container-problem{
  background-color: antiquewhite;
}

.img-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

.abt-container-problem .abt-txt{
  flex-basis: 80%;
}

.abt-container-problem .txt-wrapper{
  max-width: fit-content;
}

.abt-container-problem img{
  max-height: 25rem;
  width: auto;
}


/* team section */
.person img{
  border-radius: 50%;
  margin: 0;
  max-width: 4rem;
  max-height: 4rem;
  border: 2px solid antiquewhite;
}

.person h3{
  margin: .6rem 0;
  text-align: center;
  font-family: 'Magdelin' !important;
  font-size: .8rem;
  min-height: 3lh;
}

.person{
  
  margin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 4.5rem;

}

.team-container h3#description{
  color: antiquewhite;
  font-family: Lato;
  font-style: italic;
  font-size: .8rem;
  margin-bottom: 0;
}

.people-container{
  background-color: none;
  /* border: 3px solid black; */
  border-radius: 1.5rem;
  padding: 0;

  position: relative;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  /* scrollbar-width: none; */
    /* Firefox */
    /* -ms-overflow-style: none; */
    /* IE 10+ */

    
}

.ppl-container{
  display: flex;
  gap: 1.5rem;
  border: 3px solid antiquewhite;
  border-radius: 25px;
  padding: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: scroll;
}

.ppl-container::-webkit-scrollbar
{
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.ppl-container h3{
  font-family: 'Greycliff';
  line-height: 1.2;
}

.exec,.adv{
  font-family: 'Greycliff';
}

.team-wrapper{
  padding: 2rem;
  background-color: #6b3e3e;
  color: antiquewhite;
}

.just-img{
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  padding: 0 1rem 0 1rem;
  font-family: 'Magdelin';
}

.just-img img{
  border-radius: 50%;
  border: 2px solid antiquewhite;
  max-width: 4.5rem;
}


.description-container{
  display: flex;
  padding: 2rem 0rem;
  gap: 2rem;
}


.desc h4{
  margin: 0;
  font-style: italic;
  font-family: 'Lato';
  font-weight: normal;
}

.desc h2{
  font-family: 'Magdelin';
  font-size: 2rem;
  margin-bottom: .3rem;
  
}

.desc h2::before{
  content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}




.desc p{
  font-family: 'Magdelin';
}


.txt-wrapper h3{
  color: var(--orange);
  font-family: Magdelin;
  margin: 0;
}

.txt-wrapper h2{
  font-family: Greycliff;
  margin-bottom: 1rem;
}

.header{
  min-width: 7rem;
  min-height: 1lh;
  position: relative;
  left: 2ch;
}

.header4{
  white-space: break-spaces;
}


.people-wrapper{
  flex-grow: 1;

  min-width: 0;
}

.chip{
  background-color: antiquewhite;
  border: solid 1px #80735a;
  border-radius: 10px;
  color: #6b3e3e;
  padding: .5rem;
  font-family: 'Magdelin';
}

.title-chip{
  display: flex;
  gap: .7rem;
  align-items: center;
}




.chip:empty{
  display: none;
}

.link-active{
  color: var(--orange);
}


/* our technology */
.ourtechnology-flex-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  padding: 2rem 2rem;
  font-family: 'Magdelin';
  gap: 5rem;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;

}

.ourtechnology .foot, .news-wrapper .foot{
  flex-shrink: 0;
}

.ourtechnology{
  display: flex;
    flex-direction: column;
    height: 100%;

}

.news-flex-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;

}

.news-wrapper{
  flex: 1 0 auto;
}

html, body, #root {
  height: 100%;
}

.ourtechnology-content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}
.ourtechnology-content *{
  /* max-width: 30rem; */
}




.ourtechnology-video{
  flex-grow: 1;
  flex-shrink: 1;
}


.ourtechnology-video iframe{
  box-sizing: content-box;
  width: 100%;
  height: 15rem;
  flex-basis: 50%;
  border-radius: 25px;

}

.who{
  padding: 3rem 2rem;
}



/* News Page */

.news-jumbotron{
  border-radius: 25px;
  display: flex;
  background-color: rgb(221, 235, 248);
  /* max-width: 60rem; */
  flex: 0 0 calc(100% + 2rem);
  
}

.news{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#main{
  flex-direction: row;
  min-width: 0;
  flex-basis: 100%;
  max-width: none;;
}


#main img{
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-height: 17rem;
  min-height: 0;
  min-width: 0;
  flex-basis: 60%;
  
}

#main .info{
  flex-shrink: 1;
}

.story{
  display: flex;
  flex-direction: column;
 
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 35%;
  background-color: rgb(221, 235, 248);
  min-width: 12rem;
  max-width: 25rem;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
}


.story:hover, .news-jumbotron:hover{
  box-shadow: 0 0 3pt 2pt var(--orange);
}

.story:hover .website, .news-jumbotron:hover .website{
  color: var(--orange);
  text-decoration: underline;
}

.info{
  padding: 1rem 2rem;

}

.info h3{
  font-family: Magdelin;
  letter-spacing: .12rem;
  font-size: .7rem;
  color: grey;
}

.story .info h4{
  margin: .4rem 0;
}

.story .info h3{
  margin-bottom: .4rem;
}

.info h2{
  font-size: clamp(1.0rem, 0.24rem + 2.24vw, 1.5rem);
}

.story img{
  width: 0;
  min-width: 100%;
  object-fit: cover;
}


.news-stories-container, .news-jumbo-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 2rem;
  justify-content: center;
  max-width: 50rem;

}


.news-jumbo-container{
  justify-content: flex-start;
  flex-basis: 100%;
}


/* Contact Us Form */
.contact-form form{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  gap: 1rem;
}



.contact-us{
  background-color: antiquewhite;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.contact-form input, .contact-form textarea{
  border: black 2px solid;
  border-radius: 15px;
  padding:.7rem;
  background-color: antiquewhite;
  font-family: Magdelin;
}

.contact-form input:focus{
  border: var(--orange) 2px solid;
}

.contact-flex-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.contact-us .body{
  padding: 2rem 4rem;
}

.contact-us p{
  font-family: Magdelin;
}

.contact-us a:hover{
  color: var(--orange);
}

label{
  font-family: Magdelin;
  margin-bottom: .4rem;
  font-weight: bold;
  font-size: .9rem;
}

.input-box{
  display: flex;
  flex-direction: column;
}

.contact-us .foot{
  
 
  flex-shrink: 0;
}











@media screen and (max-width: 992px) {
  .links {
    display: none;
  }

  .contact {
    display: none;
  }



  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 9999;
  }

  nav {
    transition: height 200ms ease-in-out;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  /* ham is active */
  nav.active {
    height: 100vh;
    transition: height 300ms ease-in-out;
  }

  .hamContainer {
    display: none;
    position: absolute;
    opacity: 0;
    transition: 100ms ease-in-out;
  }

  nav.active .hamContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 3rem;
    opacity: 1;
  }


  .hamlinks {
    display: none;
    flex-direction: column;
    gap: 2rem;
    font-size: 2rem;
    opacity: 0;
  }

  nav.active .hamlinks {
    display: flex;
    opacity: 1;
    transition: 500ms;
  }

  .hamlinks .hamContact {
    font-size: 1rem;
    border-radius: 15px;
  }


  /* about page media queries */
  .abt-container-head{
    flex-direction: column-reverse;
  }

  .abt-container-mission{
    flex-direction: column-reverse;
  }

  .abt-container-problem{
    flex-direction: column-reverse;
  }

}

@media screen and (min-width: 992px){
  .jumbotron { padding-top: 8rem };
}


@media screen and (max-width: 600px) {
  #main{
    flex-direction: column;
  }
}


@media screen and (max-width: 500px){
  .description-container{
    flex-direction: column;
    padding-bottom: 0;
  }
  .just-img{
    align-self: center;
  }
  .desc h2{
    margin-bottom: .5rem;
    text-align: center;
  }
  .just-img img{
    max-width: 9.5rem;
  }
  .title-chip{
    justify-content: center;
  }

  .desc p{
    margin-top: .9rem;
    margin-bottom: .3rem;
    text-align: center;
  } 
}